import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { confirm } from "react-confirm-box";
import { useSnackbar } from "notistack";
import { Modal, Dropdown } from "react-bootstrap";

import Autosuggest from "react-autosuggest";
import Scanner from "../../barcodeFunctionality/Scanner";
import SearchIcon from "../../../assets/images/Search.svg";
import BackArrow from "../../../assets/images/back-arrow.svg";
import Barcode from "../../../assets/images/barcode.svg";
import DeleteIcon from "../../../assets/images/delete.svg";
import WarehouseContext from "../../../context/warehouseContext";
import UserContext from "../../../context/userContext";
import InventoryWarehouseService from "../../../shared/_services/inventory.service.js";

const AddWarehouseMove = () => {
  const Navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [warehouseList, setWarehouseList] = useState([]);
  const [destinationWarehouseList, setDestinationWarehouseList] = useState([]);
  const [scannedItems, setScannedItems] = useState([]);
  const [originWarehouse, setOriginWarehouse] = useState("");
  const [destinationWarehouse, setDestinationWarehouse] = useState("");
  const [originWarehouseError, setOriginWarehouseError] = useState("");
  const [quantityError, setQuantityError] = useState([]);
  const [destinationWarehouseError, setDestinationWarehouseError] =
    useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchBy, setSearchBy] = useState("Description");
  const [suggestions, setSuggestions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const warehouseCode = useContext(WarehouseContext);
  const account = useContext(UserContext);

  const newWarehouseCode = warehouseCode["newWarehouseCode"];
  const newCompanyCode = warehouseCode["newCompanyCode"];
  const Email = account?.email;
  const destinationWarehouseCompanyCode = originWarehouse.split("-.-")[1];
  console.log(destinationWarehouseCompanyCode);

  useEffect(() => {
    setScannedItems([]);
    setDestinationWarehouse([]);
    setSearchTerm("");
  }, [warehouseCode]);

  const getSuggestions = (value) => {
    const inputValue = value?.trim()?.toLowerCase();
    const inputLength = inputValue?.length;

    if (searchBy === "ItemCode") {
      return inputLength === 0
        ? []
        : suggestions?.filter((item) =>
            item.Item_Code?.toLowerCase()
              .slice(0, inputLength)
              .includes(inputValue)
          );
    } else {
      return inputLength === 0
        ? []
        : suggestions?.filter((item) =>
            item.Item_Description?.toLowerCase()
              .slice(0, inputLength)
              .includes(inputValue)
          );
    }
  };
  const onSuggestionSelected = (event, { suggestion, suggestionValue }) => {
    const isItemAlreadyScanned = scannedItems.some(
      (item) => item.Item_Code === suggestion.Item_Code
    );

    if (isItemAlreadyScanned) {
      enqueueSnackbar(`Item already added.`, {
        variant: "warning",
      });
    } else {
      setScannedItems((prevItems) => [...prevItems, suggestion]);
    }

    setSuggestions([]); // Clear suggestions
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const getSuggestionValue = (suggestion) =>
    searchBy === "ItemCode"
      ? suggestion.Item_Code
      : suggestion.Item_Description;
  const renderSuggestion = (suggestion) =>
    searchBy === "ItemCode" ? (
      <div>{suggestion.Item_Description + "-" + suggestion.Item_Code}</div>
    ) : (
      <div>
        <div>{suggestion.Item_Description + "-" + suggestion.Item_Code}</div>
      </div>
    );

  const handleSearchFun = async (event, { newValue, method }) => {
    // Implement your search logic here
    setSearchTerm(newValue.trim());

    if (method === "down" || method === "up") return;
    try {
      if (
        searchBy === "ItemCode" ? searchTerm.length > 0 : searchTerm.length >= 2
      ) {
        const response =
          await InventoryWarehouseService.getItemListByCompanyByDescription(
            enqueueSnackbar,
            newValue,
            originWarehouse.split("-.-")[0],
            searchBy,
            originWarehouse.split("-.-")[1]
          );

        if (!response.error) {
          const newItem = response.data;

          if (typeof newItem === "object") {
            if (Array.isArray(newItem)) {
              setSuggestions(newItem);
            } else {
              setSuggestions([]);
            }
          }
        }
      }
    } catch (error) {
      enqueueSnackbar("Error searching for items.", {
        variant: "error",
      });
      console.error("Error searching for items:", error);
    }
  };

  const inputProps = {
    placeholder: `Search by ${
      searchBy === "ItemCode" ? "Item Code" : "Description"
    }`,
    value: searchTerm,
    onChange: handleSearchFun,
  };

  useEffect(() => {
    InventoryWarehouseService.getWarehouseListByCompany(
      enqueueSnackbar,
      newCompanyCode
    ).then((response) => {
      if (!response.error) {
        setWarehouseList(response.data);
        console.log(response.data);
      }
    });
  }, [enqueueSnackbar, newCompanyCode]);
  console.log(originWarehouse);

  useEffect(() => {
    if (destinationWarehouseCompanyCode) {
      InventoryWarehouseService.getDestinationWarehouseListByCompanyByCode(
        enqueueSnackbar,
        destinationWarehouseCompanyCode
      ).then((response) => {
        if (!response.error) {
          setDestinationWarehouseList(response.data);
        }
      });
    }
  }, [enqueueSnackbar, destinationWarehouseCompanyCode]);

  useEffect(() => {
    setOriginWarehouse(newWarehouseCode + "-.-" + newCompanyCode || ""); // Set it to an empty string if  ++ newCompanyCode is not available
  }, [newWarehouseCode, newCompanyCode]);

  const options = {
    render: (message, onConfirm, onCancel) => {
      return (
        <div className="conform_modal">
          <h3>Are you Sure?</h3>
          <p> {message} </p>
          <div className="conform_box_btn">
            <button className="cancel_btn" onClick={onCancel}>
              Cancel
            </button>
            <button className="conform_btn" onClick={onConfirm}>
              Confirm
            </button>
          </div>
        </div>
      );
    },
  };

  const handleScanBtnClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const onDetected = (result) => {
    setIsModalOpen(false);
    fetchBarcode(result.codeResult.code);
  };

  const fetchBarcode = async (scannedBarcode) => {
    try {
      const response = await InventoryWarehouseService.getItemListByCompany(
        enqueueSnackbar,
        originWarehouse.split("-.-")[0],
        scannedBarcode,
        originWarehouse.split("-.-")[1]
      );

      if (!response.error) {
        const matchedItem = response.data.find((item) =>
          item.Barcode.some((barcodeObject) =>
            barcodeObject.barcode.includes(scannedBarcode)
          )
        );
        if (matchedItem) {
          const isItemAlreadyScanned = scannedItems.some(
            (item) => item.Item_Code === matchedItem.Item_Code
          );

          if (isItemAlreadyScanned) {
            enqueueSnackbar(`Item already present.`, {
              variant: "warning",
            });
          } else {
            enqueueSnackbar(`Item found.`, {
              variant: "success",
            });

            setScannedItems((prevItems) => [...prevItems, matchedItem]);
          }
        } else {
          enqueueSnackbar(`Item not found.`, {
            variant: "error",
          });
        }
      }
    } catch (error) {
      enqueueSnackbar(`Error fetching item list.`, {
        variant: "error",
      });
      console.log("Error fetching item list:", error);
    }
  };

  const handleRemoveItem = (index) => {
    setScannedItems((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems.splice(index, 1);
      return updatedItems;
    });
  };

  const handleQuantityChange = (index, newQuantity) => {
    if (!Number.isInteger(Number(newQuantity)) || Number(newQuantity) <= 0) {
      setQuantityError((prevQuantityError) => {
        const updatedQuantityError = [...prevQuantityError];
        updatedQuantityError[index] =
          "Quantity must be a positive whole number";
        return updatedQuantityError;
      });
    } else {
      setQuantityError((prevQuantityError) => {
        const updatedQuantityError = [...prevQuantityError];
        updatedQuantityError[index] = "";
        return updatedQuantityError;
      });
    }

    setScannedItems((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems[index].availableQuantity = newQuantity;
      return updatedItems;
    });
  };

  const validateInputFields = () => {
    if (!originWarehouse) {
      setOriginWarehouseError("Origin Warehouse is required");
      return false;
    } else if (!destinationWarehouse.length) {
      setDestinationWarehouseError("Destination Warehouse is required");
      return false;
    } else if (scannedItems.length === 0) {
      enqueueSnackbar("At least one item needs to be added", {
        variant: "error",
      });
      return false;
    }

    const hasInvalidQuantity = scannedItems?.some(
      (item) => !/^[0-9]+$/.test(item.availableQuantity)
    );
    if (hasInvalidQuantity) {
      enqueueSnackbar("Quantity must be a positive number for all items", {
        variant: "error",
      });
      return false;
    }

    return true;
  };

  const handleAddWarehouseMove = async (item) => {
    const data = {
      Warehouse: originWarehouse.split("-.-")[0].trim(),
      ToWarehouse: destinationWarehouse.trim(),
      ItemCode: item.Item_Code.trim(),
      Quantity: item.availableQuantity,
      Message: "",
      Email,
    };

    try {
      const response = await InventoryWarehouseService.AddWarehouseMove(
        data,
        enqueueSnackbar,
        originWarehouse.split("-.-")[1]
      );
      if (response.status === "success") {
        enqueueSnackbar(`Item added successfully!`, { variant: "success" });
        setTimeout(() => {
          Navigate("/warehouse");
        }, 2000);
      } else {
        enqueueSnackbar(`${response.message}`, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setOriginWarehouseError("");
    setDestinationWarehouseError("");

    if (!validateInputFields()) {
      return;
    }

    const result = await confirm(
      "Do you want to add new warehouse to warehouse move?",
      options
    );

    if (result) {
      for (const item of scannedItems) {
        await handleAddWarehouseMove(item);
      }

      setScannedItems([]);
    }
  };

  return (
    <div className="home_content inner-home-content mb-5">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="page-heading d-flex">
              <Link to="/warehouse">
                <img src={BackArrow} alt="arrow" />
              </Link>
              <h4>Add Warehouse Move</h4>
            </div>
          </div>
        </div>

        <div className="white-box-sec mt-3">
          <div className="row">
            <div className="col-lg-6">
              <label>
                Origin Warehouse <span className="text-danger">*</span>
              </label>
              <select
                className="selectpicker form-control"
                name="originWarehouse"
                value={originWarehouse}
                onChange={(e) => {
                  setOriginWarehouse(e.target.value);
                  setOriginWarehouseError("");
                }}
              >
                {warehouseList?.map((warehouse, index) => (
                  <option
                    value={
                      warehouse.Warehouse_Code.trim() +
                      "-.-" +
                      warehouse.Company_Code
                    }
                    key={warehouse.Warehouse_Code + warehouse.Company_Code}
                  >
                    {warehouse.Company_Code + " - " + warehouse.Name}
                  </option>
                ))}
              </select>
              {originWarehouseError && (
                <div className="error">{originWarehouseError}</div>
              )}
            </div>

            <div className="col-lg-6 mt-sm-2 mt-lg-0">
              <label>
                Destination Warehouse <span className="text-danger">*</span>
              </label>
              <select
                className="selectpicker form-control"
                name="destinationWarehouse"
                value={destinationWarehouse}
                onChange={(e) => {
                  setDestinationWarehouse(e.target.value);
                  setDestinationWarehouseError("");
                }}
              >
                <option value="">Select Destination Warehouse</option>
                {destinationWarehouseList?.map((warehouse, index) => (
                  <option
                    value={warehouse?.Warehouse_Code}
                    key={warehouse?.Warehouse_Code + warehouse.Company_Code}
                  >
                    {warehouse?.Company_Code + " - " + warehouse.Name}
                  </option>
                ))}
              </select>
              {destinationWarehouseError && (
                <div className="error">{destinationWarehouseError}</div>
              )}
            </div>
          </div>
        </div>

        <div className="row mt-4 d-flex align-items-center justify-content-between">
          <div className="col-2">
            <div className="items-text">
              <h6 className="m-0">
                Items
                {scannedItems.length > 1
                  ? `(${String(scannedItems.length).padStart(2, "0")})`
                  : ""}
              </h6>
            </div>
          </div>

          <div className="col-10 d-lg-block d-none">
            <div className="row d-flex align-items-center justify-content-end">
              <div className="col-lg-auto col-12 scan-item">
                <div className="d-flex search-items">
                  <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={onSuggestionsClearRequested}
                    onSuggestionSelected={onSuggestionSelected}
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestion}
                    inputProps={inputProps}
                    focusInputOnSuggestionClick={false}
                  />
                  <img src={SearchIcon} className="search-icon" alt="search" />
                  <Dropdown
                    onSelect={(selectedKey) => {
                      setSearchTerm("");
                      setSearchBy(selectedKey);
                    }}
                    className="selectBtn"
                  >
                    <Dropdown.Toggle id="dropdown-basic">
                      {searchBy === "ItemCode" ? "Item Code" : "Description"}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item eventKey="ItemCode">
                        Item Code
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="Description">
                        Description
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="col-lg-auto col-6 mt-1">
                <div className="btn-custom scan-item">
                  <button onClick={handleScanBtnClick}>
                    <img src={Barcode} alt="barcode" />
                    Scan Item
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="col-6 d-lg-none d-block">
            <div className="btn-custom scan-item">
              <button onClick={handleScanBtnClick}>
                <img src={Barcode} alt="barcode" />
                Scan Item
              </button>
            </div>
          </div>

          <div className="col-auto scan-item d-lg-none d-block">
            <div className="d-flex search-items">
              <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                onSuggestionSelected={onSuggestionSelected}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
                focusInputOnSuggestionClick={false}
              />
              <img src={SearchIcon} className="search-icon" alt="search" />
              <Dropdown
                onSelect={(selectedKey) => {
                  setSearchTerm("");
                  setSearchBy(selectedKey);
                }}
                className="selectBtn"
              >
                <Dropdown.Toggle id="dropdown-basic">
                  {searchBy === "ItemCode" ? "Item Code" : "Description"}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item eventKey="ItemCode">Item Code</Dropdown.Item>
                  <Dropdown.Item eventKey="Description">
                    Description
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>

        {scannedItems.length > 0
          ? scannedItems.map((item, index) => (
              <div className="white-box-sec mt-4" key={item?.Item_Code}>
                <div className="row">
                  <div className="mb-1 col-lg-4 col-12 item-name">
                    <label>Item Name</label>
                    <input
                      type="text"
                      placeholder="Item Name"
                      className="form-control"
                      value={item.Item_Description}
                      readOnly
                    />
                  </div>
                  <div className="mb-1 col-lg-2 col-6 mt-sm-2 mt-md-0">
                    <label>SKU</label>
                    <input
                      type="text"
                      placeholder="SKU"
                      className="form-control"
                      value={item.Item_Code}
                      readOnly
                    />
                  </div>
                  <div className="mb-1 col-lg-3 col-6 mt-sm-2 mt-md-0">
                    <label>Available Qty</label>
                    <input
                      type="text"
                      placeholder="Availble Qty"
                      className="form-control"
                      value={item.Quantity_on_Hand}
                      readOnly
                    />
                  </div>
                  <div className="mb-1 col-lg-2 col-6 mt-sm-2 mt-md-0">
                    <label>Quantity</label>
                    <input
                      type="text"
                      placeholder="Quantity"
                      className="form-control"
                      value={item.availableQuantity}
                      onChange={(e) =>
                        handleQuantityChange(index, e.target.value)
                      }
                    />
                    {quantityError && (
                      <div className="error">{quantityError[index]}</div>
                    )}
                  </div>
                  <div className="mb-1 col-lg-1 col-6 delete_icon d-flex align-items-end justify-content-end">
                    <a href="#!" onClick={() => handleRemoveItem(index)}>
                      <img src={DeleteIcon} alt="delete-icon" />
                    </a>
                  </div>
                </div>
              </div>
            ))
          : ""}

        <div className="row my-5">
          <div className="col-sm-6">
            <div className="cancel-btn">
              <Link to="/warehouse">
                <button
                  style={{
                    opacity:
                      scannedItems.length && destinationWarehouse.length
                        ? "1"
                        : "0.5",
                  }}
                >
                  Cancel
                </button>
              </Link>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="submit-btn">
              <button
                onClick={handleFormSubmit}
                type="submit"
                style={{
                  opacity:
                    scannedItems.length && destinationWarehouse.length
                      ? "1"
                      : "0.5",
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <Modal show={isModalOpen} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Scan Item</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Scanner isModalOpen={isModalOpen} onDetected={onDetected} />
          </Modal.Body>
          <Modal.Footer>
            <button className="conform_btn" onClick={handleCloseModal}>
              Stop
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default AddWarehouseMove;
