import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Modal, Dropdown } from "react-bootstrap";
import { confirm } from "react-confirm-box";
import axios from "axios";

import Scanner from "../../barcodeFunctionality/Scanner";
import WarehouseContext from "../../../context/warehouseContext";
import Autosuggest from "react-autosuggest";
import SearchIcon from "../../../assets/images/Search.svg";
import BackArrow from "../../../assets/images/back-arrow.svg";
import Barcode from "../../../assets/images/barcode.svg";
import DeleteIcon from "../../../assets/images/delete.svg";
import Constant from "../../../shared/_helpers/constants";
import UserContext from "../../../context/userContext";
import InventoryWarehouseService from "../../../shared/_services/inventory.service.js";

const AddInventoryCharge = () => {
  const Navigate = useNavigate();

  // Context and state setup
  const [scannedItems, setScannedItems] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [jobList, setJobList] = useState([]);
  const [phaseList, setPhaseList] = useState([]);
  const [jobErrorMessage, setJobErrorMessage] = useState("");
  const [phaseErrorMessage, setPhaseErrorMessage] = useState("");
  const [quantityErrorMessage, setQuantityErrorMessage] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchBy, setSearchBy] = useState("Description");
  const [suggestions, setSuggestions] = useState([]);
  const [phaseLoading, setPhaseLoading] = useState(false);
  const [compForEmployees, setCompForEmployees] = useState([]);
  const [MultiCompJobReq, setMultiCompJobReq] = useState(false);
  const warehouseCode = useContext(WarehouseContext);

  const newWarehouseCode = warehouseCode["newWarehouseCode"];
  const newCompanyCode = warehouseCode["newCompanyCode"];
  const [selectedCompany, setSelectedCompany] = useState(
    warehouseCode["newCompanyCode"]
  );
  const account = useContext(UserContext);
  const CanoID = account?.CanoID;

  async function fetchData() {
    const [CanoData, companyList] = await Promise.all([
      axios.get(
        `${Constant.BASE_URL}/api/APIv1GetCanoAccount?CanoID=${CanoID}`
      ),
      InventoryWarehouseService.getCompanyListByEmail(),
    ]);
    const filteredCompanies = companyList.data.filter(
      (cc) => !CanoData.data.RestrictedCompanies?.includes(cc.Company_Code)
    );

    setMultiCompJobReq(CanoData?.data?.AllowMultiCompJobReq);
    setCompForEmployees(filteredCompanies);

    setScannedItems([]);
    setSearchTerm("");
  }
  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line

  // State for selected Job and Phase
  const [selectedJob, setSelectedJob] = useState("");
  const [selectedPhase, setSelectedPhase] = useState("");

  const getSuggestions = (value) => {
    const inputValue = value?.trim().toLowerCase();
    const inputLength = inputValue?.length;

    if (searchBy === "ItemCode") {
      return inputLength === 0
        ? []
        : suggestions?.filter((item) =>
            item.Item_Code.toLowerCase()
              .slice(0, inputLength)
              .includes(inputValue)
          );
    } else {
      return inputLength === 0
        ? []
        : suggestions?.filter((item) =>
            item.Item_Description.toLowerCase()
              .slice(0, inputLength)
              .includes(inputValue)
          );
    }
  };
  const onSuggestionSelected = (event, { suggestion, suggestionValue }) => {
    const isItemAlreadyScanned = scannedItems.some(
      (item) => item.Item_Code === suggestion.Item_Code
    );

    if (isItemAlreadyScanned) {
      enqueueSnackbar(`Item already added.`, {
        variant: "warning",
      });
    } else {
      setScannedItems((prevItems) => [...prevItems, suggestion]);
    }

    setSuggestions([]); // Clear suggestions
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const getSuggestionValue = (suggestion) =>
    searchBy === "ItemCode"
      ? suggestion.Item_Code
      : suggestion.Item_Description;
  const renderSuggestion = (suggestion) =>
    searchBy === "ItemCode" ? (
      <div>{suggestion.Item_Description + "-" + suggestion.Item_Code}</div>
    ) : (
      <div>
        <div>{suggestion.Item_Description + "-" + suggestion.Item_Code}</div>
      </div>
    );

  const handleSearchFun = async (event, { newValue, method }) => {
    // Implement your search logic here
    setSearchTerm(newValue.trim());

    if (method === "down" || method === "up") return;
    try {
      if (
        searchBy === "ItemCode" ? searchTerm.length > 0 : searchTerm.length >= 2
      ) {
        const response =
          await InventoryWarehouseService.getItemListByCompanyByDescription(
            enqueueSnackbar,
            newValue,
            newWarehouseCode,
            searchBy,
            newCompanyCode
          );

        if (!response.error) {
          const newItem = response.data;

          if (typeof newItem === "object") {
            if (Array.isArray(newItem)) {
              setSuggestions(newItem);
            } else {
              setSuggestions([]);
            }
          }
        }
      }
    } catch (error) {
      enqueueSnackbar("Error searching for items.", {
        variant: "error",
      });
      console.error("Error searching for items:", error);
    }
  };

  const handleCompanyChange = async (cc) => {
    console.log("cc",cc)
    setSelectedCompany(cc);
    setJobList([]);
    setPhaseList([]);
    InventoryWarehouseService.getJobList(enqueueSnackbar, cc).then(
      (response) => {
        if (!response.error) {
          setJobList(response?.data);
        }
      }
    );
  };

  const inputProps = {
    placeholder: `Search by ${
      searchBy === "ItemCode" ? "Item Code" : "Description"
    }`,
    value: searchTerm,
    onChange: handleSearchFun,
  };

  // Fetch job list on component mount
  useEffect(() => {
    InventoryWarehouseService.getJobList(enqueueSnackbar, newCompanyCode).then(
      (response) => {
        if (!response.error) {
          setJobList(response?.data);
        }
      }
    );
  }, []); // eslint-disable-line

  const handleRemoveItem = (index) => {
    setScannedItems((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems.splice(index, 1);
      return updatedItems;
    });
  };
  // Open the modal for barcode scanning
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  const onDetected = (result) => {
    setIsModalOpen(false);
    fetchBarcode(result?.codeResult?.code);
  };

  const fetchBarcode = async (scannedBarcode) => {
    console.log(scannedBarcode);
    try {
      const response = await InventoryWarehouseService.getItemListByCompany(
        enqueueSnackbar,
        newWarehouseCode,
        scannedBarcode,
        newCompanyCode
      );

      if (!response.error) {
        const matchedItem = response.data.find((item) =>
          item.Barcode.some((barcodeObject) =>
            barcodeObject.barcode.includes(scannedBarcode)
          )
        );
        if (matchedItem) {
          const isItemAlreadyScanned = scannedItems.some(
            (item) => item.Item_Code === matchedItem.Item_Code
          );

          if (isItemAlreadyScanned) {
            enqueueSnackbar(`Item already scanned.`, {
              variant: "warning",
            });
          } else {
            enqueueSnackbar(`Item found.`, {
              variant: "success",
            });

            setScannedItems((prevItems) => [...prevItems, matchedItem]);
          }
        } else {
          enqueueSnackbar(`Item not found.`, {
            variant: "error",
          });
        }
      }
    } catch (error) {
      enqueueSnackbar(`Error fetching item list.`, {
        variant: "error",
      });
      console.log("Error fetching item list:", error);
    }
  };

  // Close the barcode scanning modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // Fetch phases related to the selected job
  const fetchPhasesByJob = async (JobNumber) => {
    try {
      const [phaseData, restrictedCostType] = await Promise.all([
        InventoryWarehouseService.getPhasesByJob(
          JobNumber,
          enqueueSnackbar,
          selectedCompany
        ),
        axios.get(
          `${Constant.BASE_URL}/api/APIv1GetRestrictedCostTypesForCompany?CanoID=${CanoID}&CompanyCode=SXC`
        ),
      ]);
      setPhaseLoading(false);
      if (!phaseData.error) {
        if (phaseData.getPhaseEnhanced.response === undefined) {
          return enqueueSnackbar(`No phases available against selected job`, {
            variant: "error",
          });
        }
        if (Array.isArray(restrictedCostType.data)) {
          const costTypes = restrictedCostType.data.map((ct) => {
            return ct["CostType"];
          });
          const filteredPhaseList = phaseData.getPhaseEnhanced.response.filter(
            (list) => !costTypes.includes(list.Cost_Type)
          );
          setPhaseList(filteredPhaseList);
        } else {
          setPhaseList(phaseData.getPhaseEnhanced.response);
        }
      }
    } catch (error) {
      console.error("Error fetching phases:", error);
    }
  };

  // Handle change event for the selected Job
  const handleJobChange = (event) => {
    const selectedJobNumber = event.target.value;
    setSelectedJob(selectedJobNumber);
    setPhaseList([]); // Clear the existing phase list before fetching new phases
    setSelectedPhase(""); // Clear selected phase
    setPhaseLoading(true);
    if (selectedJobNumber) {
      fetchPhasesByJob(selectedJobNumber); // Fetch phases related to the selected job
    }
    setJobErrorMessage("");
  };

  const handleQuantityChange = (index, newQuantity) => {
    let updatedQuantityErrorMessage = [...quantityErrorMessage];

    if (!Number.isInteger(Number(newQuantity))) {
      updatedQuantityErrorMessage[index] = "Quantity must be whole number";
    } else {
      updatedQuantityErrorMessage[index] = "";
    }

    setQuantityErrorMessage(updatedQuantityErrorMessage);

    setScannedItems((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems[index].availableQuantity = newQuantity;
      return updatedItems;
    });
  };

  // Handle change event for the selected Phase
  const handlePhaseChange = (event) => {
    const selectedPhaseCode = event.target.value;
    setSelectedPhase(selectedPhaseCode);
    setPhaseErrorMessage("");
  };

  const validateForm = () => {
    setPhaseErrorMessage("");
    setJobErrorMessage("");
    let isValid = true;

    if (!selectedJob) {
      setJobErrorMessage("Job is required");
      isValid = false;
    }

    if (!selectedPhase) {
      setPhaseErrorMessage("Phase is required");
      isValid = false;
    }

    // ... (perform other validation and set error states)
    if (scannedItems.length === 0) {
      enqueueSnackbar("At least one item needs to be scanned", {
        variant: "error",
      });
      isValid = false;
    }

    // Additional validation for quantities
    if (
      scannedItems?.some((item) => {
        const quantity = item.availableQuantity;
        // Use a regular expression to check if quantity contains only digits (0-9)
        if (!/^(-?\d+)$/.test(quantity)) {
          enqueueSnackbar("Quantity must be a number for all items", {
            variant: "error",
          });
          isValid = false;
          return true;
        }
        return false;
      })
    ) {
      isValid = false;
    }

    return isValid;
  };

  const handleConfirmation = async () => {
    const options = {
      render: (message, onConfirm, onCancel) => {
        return (
          <div className="conform_modal">
            <h3>Are you Sure?</h3>
            <p> {message} </p>
            <div className="conform_box_btn">
              <button className="cancel_btn" onClick={onCancel}>
                Cancel
              </button>
              <button className="conform_btn" onClick={onConfirm}>
                Confirm
              </button>
            </div>
          </div>
        );
      },
    };

    const result = await confirm(
      "Do you want to add new inventory charge?",
      options
    );

    return result;
  };

  const handleItemSubmission = async (item) => {
    const BatchCode = new Date().getTime().toString();
    var tempData = selectedPhase.split("-");
    let data = {
      BatchCode: BatchCode,
      FromWarehouse: warehouseCode.newWarehouseCode,
      ItemCode: item.Item_Code,
      ShipQuantity: item.availableQuantity.toString(),
      JobNumber: selectedJob,
      PhaseCode: tempData[0],
      PhaseName: tempData
        .slice(1, tempData.length - 1)
        .join("-")
        .replace("&amp;", "&"),
      CostType: tempData[tempData.length - 1],
      // PhaseCode: selectedPhase.split("-")[0],
      // PhaseName: selectedPhase.split("-")[1],
      // CostType: selectedPhase.split("-")[2],
    };

    if (MultiCompJobReq) {
      data["ToCompanyCode"] = selectedCompany;
    }

    try {
      const response = await InventoryWarehouseService.AddInventoryCharge(
        data,
        enqueueSnackbar,
        newCompanyCode
      );

      if (response.status === "success") {
        return {
          status: "success",
          message: "Item added successfully",
        };
      } else {
        return { status: "error", message: response.message };
      }
    } catch (error) {
      return { status: "error", message: error.message };
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const isValid = validateForm();

    if (!isValid) {
      return; // Don't proceed if any validation fails
    }

    const shouldProceed = await handleConfirmation();

    if (shouldProceed) {
      const promises = scannedItems.map(handleItemSubmission);

      const results = await Promise.allSettled(promises);

      results.forEach((item, index) => {
        if (item.value.status === "success") {
          enqueueSnackbar(`Item added successfully!`, { variant: "success" });
          Navigate("/inventory-charges");
        } else {
          enqueueSnackbar(item.value.message.status, { variant: "error" });
        }
      });

      setScannedItems([]);
    }
  };

  return (
    <>
      <div className="home_content inner-home-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="page-heading d-flex">
                <Link to="/inventory-charges">
                  <img src={BackArrow} alt="arrow" />
                </Link>
                <h4> Add Warehouse to Job </h4>
              </div>
            </div>
          </div>
          <div className="white-box-sec mt-3">
            <div className="row">
              <div className="col-lg-4">
                <label>
                  Select Company <span className="text-danger">*</span>
                </label>
                <select
                  className="form-control selectpicker"
                  name="job"
                  value={selectedCompany}
                  onChange={(e) => handleCompanyChange(e.target.value)}
                >
                  {MultiCompJobReq ? (
                    compForEmployees?.map((comp, index) => (
                      <option key={comp.Company_Code} value={comp.Company_Code}>
                        {comp.Company_Code}
                      </option>
                    ))
                  ) : (
                    <option value={selectedCompany}>{selectedCompany}</option>
                  )}
                </select>
              </div>

              <div className="col-lg-4">
                <label>
                  Job <span className="text-danger">*</span>
                </label>
                <select
                  className="form-control selectpicker"
                  name="job"
                  onChange={handleJobChange}
                >
                  <option value="">Select Job</option>
                  {jobList?.map((job, index) => (
                    <option key={job.Job_Number + index} value={job.Job_Number}>
                      {job.Job_Number}{" "}
                      {job.Job_Description?.replace("&amp;", "&")}
                    </option>
                  ))}
                </select>
                {jobErrorMessage && (
                  <div className="error">{jobErrorMessage}</div>
                )}
              </div>

              <div className="col-lg-4 mt-sm-3 mt-lg-0 ">
                <label>
                  Phase <span className="text-danger">*</span>
                </label>
                <select
                  className="form-control selectpicker w-100"
                  name="phase"
                  value={selectedPhase}
                  onChange={handlePhaseChange}
                >
                  <option value="">Select Phase</option>
                  {phaseLoading ? (
                    <option value="" disabled>
                      Loading Phases...
                    </option>
                  ) : phaseList ? (
                    Array.isArray(phaseList) ? (
                      phaseList.map((phase, index) => (
                        <option
                          key={phase.Phase_Code + index}
                          value={
                            phase.Phase_Code +
                            "-" +
                            phase.Description +
                            "-" +
                            phase.Cost_Type
                          }
                        >
                          {phase.Phase_Code +
                            " - " +
                            phase?.Description?.replace("&amp;", "&") +
                            " - " +
                            phase.Cost_Type}
                        </option>
                      ))
                    ) : (
                      <option
                        value={
                          phaseList?.Phase_Code +
                          "-" +
                          phaseList?.Description +
                          "-" +
                          phaseList?.Cost_Type
                        }
                      >
                        {phaseList.Phase_Code +
                          " - " +
                          phaseList?.Description?.replace("&amp;", "&") +
                          " - " +
                          phaseList.Cost_Type}
                      </option>
                    )
                  ) : (
                    <option value="" disabled>
                      No phases available
                    </option>
                  )}
                </select>

                {/* Display custom validation error */}
                {phaseErrorMessage && (
                  <div className="error">{phaseErrorMessage}</div>
                )}
              </div>
            </div>
          </div>

          <div className="row mt-4 d-flex align-items-center justify-content-between">
            <div className="col-2">
              <div className="items-text">
                <h6 className="m-0">
                  Items
                  {scannedItems.length > 1
                    ? `(${String(scannedItems.length).padStart(2, "0")})`
                    : ""}
                </h6>
              </div>
            </div>

            <div className="col-10 d-lg-block d-none">
              <div className="row d-flex align-items-center justify-content-end">
                <div className="col-lg-auto col-12 scan-item">
                  <div className="d-flex search-items align-items-center">
                    <Autosuggest
                      suggestions={suggestions}
                      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                      onSuggestionsClearRequested={onSuggestionsClearRequested}
                      onSuggestionSelected={onSuggestionSelected}
                      getSuggestionValue={getSuggestionValue}
                      renderSuggestion={renderSuggestion}
                      inputProps={inputProps}
                      focusInputOnSuggestionClick={false}
                      style={{ height: "100%" }}
                    />
                    <img
                      src={SearchIcon}
                      className="search-icon"
                      alt="search"
                    />
                    <Dropdown
                      onSelect={(selectedKey) => {
                        setSearchTerm("");
                        setSearchBy(selectedKey);
                      }}
                      className="selectBtn"
                    >
                      <Dropdown.Toggle id="dropdown-basic">
                        {searchBy === "ItemCode" ? "Item Code" : "Description"}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item eventKey="ItemCode">
                          Item Code
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="Description">
                          Description
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <div className="col-lg-auto col-6 mt-1">
                  <div className="btn-custom scan-item">
                    <button onClick={handleOpenModal}>
                      <img src={Barcode} alt="barcode" /> Scan Item
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-6 d-lg-none d-block">
              <div className="btn-custom scan-item">
                <button onClick={handleOpenModal}>
                  <img src={Barcode} alt="barcode" /> Scan Item
                </button>
              </div>
            </div>

            <div className="col-auto scan-item d-lg-none d-block">
              <div className="d-flex search-items align-items-center">
                <Autosuggest
                  suggestions={suggestions}
                  onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                  onSuggestionsClearRequested={onSuggestionsClearRequested}
                  onSuggestionSelected={onSuggestionSelected}
                  getSuggestionValue={getSuggestionValue}
                  renderSuggestion={renderSuggestion}
                  inputProps={inputProps}
                  focusInputOnSuggestionClick={false}
                />
                <img src={SearchIcon} className="search-icon" alt="search" />
                <Dropdown
                  onSelect={(selectedKey) => {
                    setSearchTerm("");
                    setSearchBy(selectedKey);
                  }}
                  className="selectBtn"
                >
                  <Dropdown.Toggle id="dropdown-basic">
                    {searchBy === "ItemCode" ? "Item Code" : "Description"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item eventKey="ItemCode">Item Code</Dropdown.Item>
                    <Dropdown.Item eventKey="Description">
                      Description
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>

          {scannedItems?.map((item, index) => (
            <div className="white-box-sec mt-3" key={item.Item_Code}>
              <div className="row">
                <div className="mb-1 col-lg-4 col-12 item-name">
                  <label>Item Name</label>
                  <input
                    type="text"
                    placeholder="Item Name"
                    className="form-control"
                    value={item.Item_Description}
                    readOnly
                  />
                </div>
                <div className="mb-1 col-lg-2 col-6 mt-sm-2 mt-md-0">
                  <label>SKU</label>
                  <input
                    type="text"
                    placeholder="SKU"
                    className="form-control"
                    value={item.Item_Code}
                    readOnly
                  />
                </div>
                <div className="mb-1 col-lg-3 col-6 mt-sm-2 mt-md-0">
                  <label>Available Qty</label>
                  <input
                    type="text"
                    placeholder="SKU"
                    className="form-control"
                    value={item.Quantity_on_Hand}
                    readOnly
                  />
                </div>
                <div className="mb-1 col-lg-2 col-6 mt-sm-2 mt-md-0">
                  <label>Quantity</label>
                  <input
                    type="text"
                    placeholder="Quantity"
                    className="form-control"
                    value={item.availableQuantity}
                    onChange={(e) =>
                      handleQuantityChange(index, e.target.value)
                    }
                  />
                  {quantityErrorMessage && (
                    <div className="error">{quantityErrorMessage[index]}</div>
                  )}
                </div>
                <div className="mb-1 col-lg-1 col-6 delete_icon d-flex align-items-end justify-content-end">
                  <a href="#!" onClick={() => handleRemoveItem(index)}>
                    <img src={DeleteIcon} alt="delete-icon" />
                  </a>
                </div>
              </div>
            </div>
          ))}
          <div className="row my-5 d-flex justify-content-between">
            <div className="col-auto">
              <div className="cancel-btn">
                <Link to="/inventory-charges">
                  <button
                    style={{
                      opacity:
                        scannedItems.length &&
                        selectedJob.length &&
                        selectedPhase
                          ? "1"
                          : "0.5",
                    }}
                  >
                    Cancel
                  </button>
                </Link>
              </div>
            </div>
            <div className="col-auto">
              <div className="submit-btn">
                <button
                  type="submit"
                  onClick={handleFormSubmit}
                  style={{
                    opacity:
                      scannedItems.length && selectedJob.length && selectedPhase
                        ? "1"
                        : "0.5",
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <Modal show={isModalOpen} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Scan Item</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Scanner isModalOpen={isModalOpen} onDetected={onDetected} />
          </Modal.Body>
          <Modal.Footer>
            <button className="conform_btn" onClick={handleCloseModal}>
              Stop
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default AddInventoryCharge;
